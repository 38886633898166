.btn-card {
    background-color: black;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 11px;
    width: 100%;
    height: 50px;
    margin-top: 0px;
    cursor: pointer;
    margin-bottom: 10%;
}

.greenDot {
    /* Ellipse 3 */

    position: absolute;
    width: 15px;
    height: 15px;
    margin-left: 0.5%;
    margin-top: 0.4%;
    background: #11da00;
    border-radius: 255px;
}

.card-container {
    display: grid;
    grid-gap: 50px;
    grid-template-columns: auto auto;
    padding: 10px;
    margin-left: 5%;
    margin-top: 5%;
}

.card-item {
    background: #D9D5D5;

    padding: 20px;
    font-size: 30px;
    text-align: center;
    border-radius: 5px;
    width: 500px;    
    height: 500px;
    margin-left: 10px;

}

.card-item-2 {
    background: #D9D5D5;
    border: 0.5%  #000000;
    padding: 20px;
    font-size: 30px;
    text-align: center;
    border-radius: 5px;
    width: 500px;
    height: 310px;
    margin-left: 10px;    
}

@media (max-width: 300px) {
    .card-container {
        grid-template-columns: repeat(1, 1fr);
    }
}
