.btn {
    background-color: black;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 15px;
    width: 100%;
    height: 50px;
    margin-top: 0px;
    cursor: pointer;
}

.greenDot {
    /* Ellipse 3 */

    position: absolute;
    width: 15px;
    height: 15px;
    margin-left: 0.5%;
    margin-top: 0.4%;
    background: #11da00;
    border-radius: 255px;
}

.grid-container {
    display: grid;
    grid-gap: 2px;
    grid-template-columns: auto auto auto;
    padding: 10px;
    margin-left: 5%;
    margin-top: 5%;
}

.grid-item {
    background-color: #EFEFEF;
    border: none;    
    padding: 20px;
    font-size: 30px;
    text-align: center;
    border-radius: 2px;
    width: 272px;
    height: 150px;
    cursor: pointer;
}


.nft-item {
    background-color: white;
    border: none;    
    padding: 20px;
    font-size: 30px;
    text-align: center;
    border-radius: 2px;
    width: 272px;
    height: 250px;
    cursor: pointer;
}


@media (max-width: 300px) {
    .grid-container {
        grid-template-columns: repeat(1, 1fr);
    }
}
