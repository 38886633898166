body {
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
}

.topnav {
    overflow: hidden;
    background-color: white;
}

.topnav a {
    float: left;
    color: black;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    margin-top: 10px;
}

.topnav a:hover {
}

.topnav a.active {
    margin-top: 0px;
}

.topnav-right {
    float: right;
}

.line {
    width: 100%;
    height: 0px;

    border: 0.1px solid #000000;
}
